<template>
  <div class="posters">
    <div class="top_bar">
      <div class="top_bar_icon" @click="handleRouter">
        <van-icon name="arrow-left" color="#7e7e7e" size="16" />
      </div>
      <div>推广名片</div>
    </div>
    <div class="postersBac" id="capture" ref="capture">
      <div class="bacsImg">
        <!-- <img :src="bacImg" alt id="bacImg" /> -->
        <van-swipe :autoplay="3000" loop>
          <van-swipe-item v-for="(item, index) in bacImg" :key="index">
            <img :src="item" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <!-- <div class="code_img">
        <img :src="codeImg" alt id="codeImg" />
        <div>扫描二维码</div>
      </div> -->
    </div>
    <!-- <div class="imgDiv" v-show="show" @click="show = false">
      <img :src="imgSrc" id="imgShow" alt @click.stop proxy="http://hzp.kunchuangtech.net/Public" />
    </div> -->
    <!-- footer -->
    <div class="footer">
      <div class="btn">长按图片保存海报</div>
    </div>
  </div>
</template>
<script>
// import html2canvas from "html2canvas";

export default {
  data() {
    return {
      show: false,
      bacImg: [],
      codeImg: require("../../assets/image/code.png"),
      imgSrc: ""
    };
  },
  methods: {
    handleRouter() {
      this.$router.go(-1);
    },
    // btn() {
    //   html2canvas(this.$refs.capture, {
    //     scale: 1,
    //     // proxy:"http://hzp.kunchuangtech.net/Public",
    //     // useCORS: true
    //     useCORS: true, // 【重要】开启跨域配置
    //     allowTaint: true, //允许跨域图片
    //     taintTest: false //是否在渲染前测试图片
    //   }).then(canvas => {
    //     // console.log(canvas.toDataURL());
    //     let _imgSrc = canvas.toDataURL("image/png", 1);
    //     // var imgShow = document.querySelector("#imgShow");
    //     // console.log(imgShow, ",imgshow");
    //     // imgShow.setAttribute("src", _imgSrc);
    //     this.imgSrc = _imgSrc;
    //     this.show = true;
    //   });
    // },
    // async handleGetCode() {
    //   const data = await this.$api.getCode();
    //   if (data.data.code === 1) {
    //     this.bacImg = data.data.data.hb_img;
    //     this.codeImg = data.data.data.geterwemaurl;
    //   } else {
    //     this.$notify({ type: "danger", message: data.data.msg });
    //   }
    //   console.log(data, "data");
    // }
    async handleGetCode() {
      const data = await this.$api.getHb();
      if (data.data.code === 1) {
        this.bacImg = data.data.data;
        // this.codeImg = data.data.data.geterwemaurl;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
      console.log(data, "data");
    }
  },
  mounted() {
    this.handleGetCode();
  }
};
</script>
<style lang="scss" scoped>
.posters {
  width: 375px;
  height: 100%;
  padding-top: 40px;
  box-sizing: border-box;
  .postersBac {
    width: 375px;
    height: calc(100vh - 123px);
    // background:url("../../assets/image/details.png");
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    .bacsImg {
      width: 375px;
      // height: calc(100vh - 123px);
      position: absolute;
      top: 0;
      left: 0;
      object-fit: contain;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .code_img {
      width: 110px;
      height: 135px;
      position: fixed;
      bottom: 83px;
      right: 0;
      z-index: 2;
      font-size: 12px;
      color: rgba(255, 255, 255, 1);
      line-height: 12px;
      padding-top: 35px;
      box-sizing: border-box;
      img {
        width: 73px;
        height: 73px;
        margin-bottom: 6px;
      }
    }
  }
  .imgDiv {
    width: 375px;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 0, 0.4);
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    z-index: 4;
    text-align: center;
    #imgShow {
      width: 295px;
      height: calc(100vh - 80px);
      object-fit: contain;
    }
  }
  .footer {
    width: 375px;
    height: 83px;
    position: fixed;
    bottom: 0;
    z-index: 2;
    padding-top: 23px;
    box-sizing: border-box;
  }
}
</style>
